export const environment = {
  isProduction: false,
  envName: 'staging',
  enableDebugTools: true,
  originalApiRootUrl: 'https://apistaging.solidmemory.com',
  stsRootUrl: 'https://stsstaging.solidmemory.com',
  apiRootUrl: 'https://wwwstaging.solidmemory.com/api',
  wsRootUrl: 'https://wwwstaging.solidmemory.com/ws',
  dictionaryRootUrl: 'https://wwwstaging.solidmemory.com/api/dictionary',
  imageRootUrl: 'https://wwwstaging.solidmemory.com/api/image',
  audioRootUrl: 'https://wwwstaging.solidmemory.com/api/audio',
  erpRootUrl: 'https://wwwstaging.solidmemory.com/api/erp',
  publicKey:
    'BN-IlMMq-1GitoXY1s22VGvNEtcW3FCyp8sx_tqIOUg9wysroVPXDuNaXwnqxKHZtNRkrR9J-O8VWDaeewVAHU0',
  enableServiceWorker: true,
  payDollarUrl: 'https://test.paydollar.com/b2cDemo/eng/payment/payForm.jsp',
  payDollarMerchantId: 88147816,
  payPalScriptSrc:
    'https://www.paypal.com/sdk/js?client-id=AbjHHyP_SiFONXIwYfqk5JCWMT1hmyz1gWYEGgypDwlx7PkqoSGxvAdK4Jdk6yV3r5B0q44CcNpjrQiT&currency=HKD',
  enableConnectionCheck: true,
  version: 'v2024.10.10.13',
  sentryDsn:
    'https://f9e1176429d84fa9a18887b96dfc51bb@o532726.ingest.sentry.io/5651959',
  sentryDsnp1:
    'https://fdf9736ffb944b5fa0a7109b702ab75b@o535904.ingest.sentry.io/5654380',
  sentrySampleRate: 1.0,
  sentryTracesSampleRate: 1.0,
  showSentryDialog: false,
  updateCheckInterval: 60000, //60s
  pubKey : `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAjijam08i970DOo/f6W8f
aFOtYuAICmdHP5/iNfv9MQELd45uDHgUsslhVk5V5k2dCkLQi5UJIUQuxMOpPJ7o
gjOVawoPtxwDQi/bCPwHHjMDRXZdtvUX5tfBqjY3xToRe1zMyVN3M2M9Mv4aTN7o
7PP/Z6ICq430DwKctQ94MX9vPTgwcmcadfbcWJ7PdPgKWqpalpV8KjSK9HMt50v+
tzdMwkZ4A+3TEtwQzakHWsJ0HfKrf39tf21jNTLSdWprlxMDNNqvXjBgbFiF/en4
bUuS6+TdtLFvf+J+CzWjtwTpfA39gWKzk7X95NvAKeLPzC5ZB/HeWOl39w5DnN+s
YcoU30/bWRS8w8li6ql9b6Rm+Zvdd4UthcdpAqVMvPXffD9w2VcGR+JKeltYhPyn
2pwwaxaZ5getI94mxe1DyRCFoBbjbSxGNw9gdXlbkfO5oY24eHfGPViL14bqlfYG
3xGnbZrPerCJPY3fuQ2i/lP0IHn9JA7n6xsBctZmJOfeYi26U8XjZo6HSLESPYuG
4oWqDd1tp/XX4Xjv+lgqZAChYB9D4KXgrVkRt0Jc90LSJBQqlt/EzvIltd9SBpVj
HSzdzWAMeYh5VbwqlzsnQXeRzQUoABKAFi2bT3nJ1teS+gnZdQW4UmUBRMumTQ7k
TYYw2ksubxIQS5FWfLqcSnMCAwEAAQ==
-----END PUBLIC KEY-----`
};
